/* SharedMenuStyles.scss */

/* Estilos compartidos para menús */
.navigation-menu,
.user-menu {
  position: relative;

  .menu-toggle {
    display: flex;
    align-items: center;
    cursor: pointer;

    .menu-circle {
      width: 40px;
      height: 40px;
      background-color: var(--secondary-color);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text-secondary {
      margin-left: 8px;
      color: #333;
    }
  }

  /* Aquí definimos los estilos para cualquier menú desplegable. */
  .navigation-dropdown,
  .user-menu-dropdown {
    position: absolute;
    top: 50px;
    left: 0;
    background-color: white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: visible; /* Importante para que submenús anidados no queden cortados */
    z-index: 10;
    width: auto; /* Se ajustará según el contenido; si prefieres fijo, cámbialo a px o rem */

    .dropdown-item {
      padding: 10px 15px;
      transition: background-color 0.3s ease;
      cursor: pointer;

      &:hover {
        background-color: var(--secondary-color);
        color: white;
      }

      a {
        text-decoration: none;
        color: inherit;
        display: block;
      }
    }

    .dropdown-divider {
      height: 1px;
      background-color: #ddd;
      margin: 5px 0;
    }
  }

  /* Animaciones con CSSTransition */
  .dropdown-enter {
    opacity: 0;
    transform: translateY(-20px);
  }
  .dropdown-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 0.3s, transform 0.3s;
  }
  .dropdown-exit {
    opacity: 1;
    transform: translateY(0);
  }
  .dropdown-exit-active {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.3s, transform 0.3s;
  }
}

/* Estilo adicional para separar ícono de usuario y el carrito */
.user-menu,
.navigation-menu {
  margin-left: 1rem;
}

/* Hover y transiciones para el ícono */
.user-menu .menu-toggle .menu-circle,
.navigation-menu .menu-toggle .menu-circle {
  transition: background-color 0.3s ease;
}

.user-menu .menu-toggle:hover .menu-circle,
.navigation-menu .menu-toggle:hover .menu-circle {
  background-color: var(--secondary-color); /* Cambia a tu color hover */
}

/* ========================= */
/* Submenús anidados (DropdownMenu) */
/* ========================= */
.dropdown-submenu {
  position: relative; 
}

/* Menú interno del submenú */
.dropdown-submenu .dropdown-menu {
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: 0.1rem;
  display: none;
  min-width: 200px; /* Ajusta el ancho mínimo; o usa width: auto; si prefieres algo flexible */
  z-index: 999; /* Elevar sobre otros elementos */
}

/* Mostrar el submenú al hacer hover o si React-Bootstrap le asigna la clase .show */
.dropdown-submenu:hover .dropdown-menu,
.dropdown-submenu.show .dropdown-menu {
  display: block;
}

/* ============================= */
/* Mega-menu (3 columnas)       */
/* ============================= */

.mega-menu {
  display: flex;
  flex-direction: row; /* Columnas horizontales por defecto */
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  min-width: 600px; /* Ancho mínimo para mantener consistencia */
  max-width: 900px; /* Ajusta según tus necesidades */
  column-gap: 1rem; /* Espacio entre columnas */
  transition: all 0.3s ease; /* Transiciones suaves para cambios visuales */

  /* Comportamiento en pantallas pequeñas */
  @media (max-width: 768px) {
    flex-direction: column; /* Cambia a apilado vertical */
    min-width: 100%; /* Usa todo el ancho disponible */
    max-width: 100%; /* Elimina límites de ancho */
    padding: 0.5rem; /* Reduce los márgenes */
    box-shadow: none; /* Elimina sombra si prefieres un diseño plano */
    border-radius: 0; /* Diseño rectangular */
  }
}

.mega-menu-column {
  display: flex;
  flex-direction: column;
  min-width: 250px;
  max-width: 250px; /* Fija el ancho máximo de cada columna */
  transition: all 0.3s ease; /* Transiciones suaves */

  /* Alinear al 100% en pantallas pequeñas */
  @media (max-width: 768px) {
    min-width: 100%; /* Cada columna ocupa todo el ancho */
    max-width: 100%; /* Sin límites de ancho */
  }

  .mega-menu-column-title {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .mega-menu-item {
    margin-bottom: 0.3rem;
    padding: 0.4rem 0.6rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;

    /* Asegurar altura fija */
    height: 48px; /* Ajusta según el diseño */
    display: flex;
    align-items: center; /* Centra verticalmente el texto */
    white-space: normal; /* Permite texto en varias líneas */
    overflow: hidden; /* Previene desbordamiento de texto */
    text-overflow: ellipsis;

    a {
      text-decoration: none;
      color: #333;
      display: block;
      line-height: 1.2; /* Ajusta el espaciado entre líneas */
    }

    &:hover {
      background-color: var(--secondary-color);
      a {
        color: #fff;
      }
    }

    &.active {
      background-color: var(--secondary-color);
      a {
        color: #fff;
      }
    }

    &.disabled {
      color: #999;
      pointer-events: none;
    }
  }
}

/* Estilos adicionales para el contenedor */
.navigation-dropdown {
  position: absolute;
  top: 50px;
  left: 0;
  z-index: 1000;
  overflow: visible;

  /* Responsive: Haz que el menú ocupe todo el ancho */
  @media (max-width: 768px) {
    position: static; /* Elimina posición absoluta */
    width: 100%; /* Usa todo el ancho */
    margin: 0; /* Sin márgenes */
    border-radius: 0; /* Menú rectangular */
    box-shadow: none; /* Elimina sombra si lo prefieres */
  }

  /* Transiciones para animar el ancho */
  transition: all 0.3s ease;
}



