$primary-color: #2954a3; /* Azul oscuro */
$secondary-color: #ff7e1b; /* Naranja */
$light-color: #f8f9fa;
$dark-color: #333;

@import "~bootstrap/scss/bootstrap";

html {
  scroll-behavior: smooth;
}

/* Sección de Bienvenida (modificación) */
.bg-primary {
  background: linear-gradient($primary-color,#2954a3); /* Usando color sólido */
  color: white !important;
  text-align: center;
  padding: 4rem 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Estilos para el título y subtítulo en la sección de bienvenida */
.bg-primary h1 {
  font-family: 'Roboto', sans-serif;
  font-size: 2.8rem;
  color: white !important; /* Color blanco asegurado para el h1 */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); /* Sombra de texto suave */
  transition: transform 0.3s ease, font-size 0.3s ease; /* Animación de zoom y cambio de tamaño en hover */
}

.bg-primary h1:hover {
  transform: scale(1.05); /* Zoom suave en hover */
  font-size: 2.9rem; /* Cambio sutil de tamaño */
}

.bg-primary p {
  font-size: 1.3rem;
  color: white !important; /* Color blanco asegurado para el subtítulo */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2); /* Sombra de texto en el subtítulo */
  transition: font-size 0.3s ease; /* Transición suave en el tamaño */
}

.bg-primary p:hover {
  font-size: 1.35rem; /* Efecto de hover en el subtítulo */
}

body {
  background-color: $light-color;
  font-family: 'Roboto', sans-serif;
}

h2, h3, h4, h5, h6 {
  color: $dark-color;
}

/* Estilo para la primera sección de la barra de navegación */
.navbar-top {
  background-color: white;
  border-bottom: 1px solid #ddd;
}

/* Estilo para la segunda sección (separador) */
.navbar-bottom {
  background-color: #2954a3; /* Cambia a tu color deseado */
  color: rgb(255, 255, 255);
  
  .nav-link {
    color: white !important;
  }

  .nav-link:hover {
    color: #ffcc00 !important; /* Color de hover opcional */
  }
}


.btn-menu {
  background-color: $secondary-color;
  color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}



/* Icono de sucursales */
.icono-sucursales, .button-sucursales {
  color: $secondary-color;
  margin-right: 10px;
}

.button-sucursales {
  color: $secondary-color;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

/* Sección de Características */
.features {
  background-color: #f3f3f3;
  padding: 30px 0;
  text-align: center;

  .feature-item {
    h3 {
      color: $primary-color;
      font-size: 1.2rem;
    }

    p {
      color: #666;
    }

    .feature-icon {
      color: $primary-color;
      font-size: 2rem;
      margin-bottom: 10px;
    }
  }
}

/* Modal de Sucursales */
.modal-header, .modal-footer {
  border: none;
}

.modal-content {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;

  .btn {
    margin: 5px 0;
    color: $secondary-color;
    border-color: $secondary-color;
    &:hover {
      background-color: $secondary-color;
      color: #fff;
    }
  }
}

/* Footer */
.footer {
  background-color: #2954a3; // Cambia este color si deseas otro tono
  color: white;
  text-align: center;
  padding: 1rem 0;
  margin-top: auto;
}


/* Scroll to top button */
.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  background-color: $primary-color;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 50%;
  font-size: 24px;
}

:root {
  --secondary-color: #ff7e1b; /* Naranja */
}

.menu-circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: $secondary-color;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* Estilo de los iconos */
.text-secondary {
  color: $secondary-color !important; /* Usando variable SCSS */
}


/* Estilo de los iconos */
.text-secondary {
  color: var(--secondary-color) !important;
}

/* Estilo para la barra de búsqueda */
.search-bar .form-control {
  border-radius: 20px;
  padding-right: 30px;
  border-right: none;
}

.search-icon {
  color: #ff7e1b; /* Naranja */
  cursor: pointer;
  border-radius: 0 20px 20px 0;
}

/* Estilo para el icono de lupa dentro de la barra de búsqueda */
.search-bar .form-control {
  border-radius: 20px;
  border-right: none;
}

.search-icon {
  color: $secondary-color;
  cursor: pointer;
  border-radius: 0 20px 20px 0;
}

.carrito-icon-container {
  position: relative; // Permite posicionar el badge relativo al ícono
  display: inline-block;
}

.carrito-badge {
  position: absolute;
  top: -5px; // Ajusta la posición vertical
  right: -10px; // Ajusta la posición horizontal
  background-color: red;
  color: white;
  border-radius: 50%; // Hace que el badge sea circular
  padding: 2px 6px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  min-width: 18px;
  text-align: center;
}

.card-img-top-custom {
  height: 200px;
  object-fit: cover;
}

.pocas-unidades {
  background-color: red;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  margin-left: 5px;
}

button:disabled {
  background-color: gray !important;
  cursor: not-allowed;
#dropdown-user {
  background-color: transparent !important; /* Fondo transparente */
  border: none !important; /* Sin borde */
  box-shadow: none !important; /* Elimina cualquier sombra */
  padding: 0; /* Ajusta el padding si es necesario */
}

/* Cambiar el hover del botón para que sea consistente */
#dropdown-user:hover {
  background-color: transparent !important; /* Mantén el fondo transparente al hacer hover */
  color: var(--secondary-color) !important; /* Asegura que el ícono se mantenga naranja */
}

/* Cambia el ícono para ser consistente */
#dropdown-user .text-secondary {
  color: var(--secondary-color) !important; /* Usando el esquema de color naranja */
}}

.notification-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Oscurece el fondo */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Estilos para la notificación */
.notification-popup {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  width: 400px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.notification-content {
  position: relative; /* El contenedor define el contexto para el botón */
  padding: 10px; /* Ajusta el espacio interno según sea necesario */
}

.btn-close {
  position: absolute; /* Botón posicionado respecto al contenedor */
  top: 10px; /* Espaciado desde la parte superior */
  right: 10px; /* Espaciado desde la derecha */
  width: 24px; /* Tamaño fijo */
  height: 24px; /* Tamaño fijo */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent; /* Fondo transparente */
  border: none; /* Sin borde */
  color: #888; /* Color del ícono */
  cursor: pointer; /* Cursor de clic */
  font-size: 20px; /* Tamaño de la fuente para el ícono */
}

.btn-close:hover {
  color: #333; /* Cambia el color al hacer hover */
  transform: scale(1.1); /* Añade un efecto de aumento */
}

.notification-popup {
  position: relative; /* Para manejar el contenido interno */
  background-color: white;
  border-radius: 8px; /* Esquinas redondeadas */
  padding: 20px; /* Espaciado interno */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* Sombra */
  max-width: 90%; /* Ajusta según el diseño */
  width: 400px; /* Tamaño fijo */
}

.slide-enter {
  opacity: 0;
  transform: translateX(100px); 
  /* La vista entra desde la derecha. Puedes aumentar el valor o cambiarlo a -100px para que entre desde la izquierda. */
}
.slide-enter.slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: transform 300ms ease-out, opacity 300ms ease-out;
}

.slide-exit {
  opacity: 1;
  transform: translateX(0);
}
.slide-exit.slide-exit-active {
  opacity: 0;
  transform: translateX(-100px); 
  /* La vista se mueve hacia la izquierda al salir. Puedes cambiar el valor o la dirección. */
  transition: transform 300ms ease-out, opacity 300ms ease-out;
}